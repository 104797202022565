<template>
    <section class="content">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-primary">
                    <!-- /.card-header -->
                    <!-- form start -->
    <form role="form" @submit.prevent="submitForm">
        <div class="invoice p-3 mb-3">
            <div class="row">
                <div class="card card-default">
                    <!-- <form role="form" @submit="submitForm"> -->
                    <div class="card-header">
                        <h3 class="card-title"></h3>

                        <div class="card-tools">
                            <button
                            type="button"
                            class="btn btn-tool"
                            data-card-widget="collapse"
                            >
                            <i class="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label class="control-label">Judul</label>
                        <input id="title" class="form-control" v-model="form.title" type="text" name="title" />
                    </div>
                    <div class="col-md-12 form-group">
                        <label class="control-label">Kode (Tanpa Spasi)</label>
                        <input id="code" class="form-control" v-model="form.code" type="text" name="code" />
                    </div>
                    <div class="col-md-12">
                    <label class="control-label">Isi Info</label>
                    <div id="app">
                        <ckeditor v-model="form.content" :config="editorConfig"></ckeditor>
                    </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-1">Simpan</button>
            <router-link to="/info" class="btn btn-secondary">
            Kembali
            </router-link>
        </div>
    </form>
        </div>
    </div>
</div>
    </section>
</template>
<script>
import Vue from "vue";
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import CKEditor from 'ckeditor4-vue';
// import { VueEditor } from "vue2-editor";
// Vue.use( CKEditor );

export default {
    data() {
        return {
            errors: [],
            optMapel: [],
            roles: "",
            method: "POST",
            form: {
                content: "<h1>Ketik info di sini..</h1>",
                title: "",
                code: "",
            },
            editorConfig: {}
        };
    },
    components: { 
        ckeditor: CKEditor.component,
        // vSelect,
    },
    created: function() {
        // alert(this.$route.params.id);
        if (this.$route.params.id) {
            this.loadInfo(this.$route.params.id);
            this.method = "PUT";
        }
    },
    methods: {
        loadInfo(id) {
            authFetch("/info/" + id).then((res) => {
                res.json().then((json) => {
                    if (json) {
                        this.form = json;
                    } else {
                        Swal.fire("Error", ``, "error");
                    }
                });
            });
        },
        submitForm: function (ev) {
            const e = this.$refs;
            var data = Object.keys(this.form)
                .map(
                (key) =>
                    encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
                )
                .join("&");
            var urlSubmit = "/info";
            if (this.method == "PUT")
                urlSubmit = "/info/" + this.form.id;

            Swal.fire({
                title: "Simpan?",
                showCancelButton: true,
                confirmButtonText: `Ya`,
                cancelButtonText: "Tidak",
            }).then((result) => {
                if (result.isConfirmed) {
                authFetch(urlSubmit, {
                    method: this.method,
                    body: data,
                })
                    .then((res) => {
                    return res.json();
                    })
                    .then((js) => {
                    if (js.success) {
                        Swal.fire("Proses Berhasil", ``, "success");
                        this.form = {};
                        this.$router.push('/info');
                    } else {
                        Swal.fire("Proses gagal", ``, "error");
                        }
                    });
                }
            });
            ev.preventDefault();
        },
    },
    mounted() {
        const e = this.$refs;
        const self = this;

        CKEditor.editorConfig = function( config ) {
	config.toolbar = [
		{ name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates' ] },
		{ name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
		{ name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
		{ name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
		'/',
		{ name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
		{ name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
		{ name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
		{ name: 'insert', items: [ 'Image', 'Flash', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
		'/',
		{ name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
		{ name: 'colors', items: [ 'TextColor', 'BGColor' ] },
		{ name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
		{ name: 'about', items: [ 'About' ] }
	];
};
    },
}
</script>